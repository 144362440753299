/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  FormControl,
  Typography,
  TextField,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useEffect, useMemo, useRef, useState } from "react";
import AutoCompleteInput from "../../../Components/FormComponents/AutoCompleteInput";
import { useTranslation } from "react-i18next";
import IconExpandInformation from "Components/Result/icons/IconExpandInformation";
import IconHideInfomation from "Components/Result/icons/IconHideInfomation";
import { useTheme } from "@mui/material/styles";
import { useContext } from "react";
import UserContext from "userContext";
import _ from 'lodash';
import { SelectOption, CarCompanyInfo, CarInfoModel } from "./type";
import {
  getCarInfoCompany,
  getCarInfoModel,
  getCarManufacturedYears,
  getCarVersion,
  getListOrigin,
  getKmTraveledSuggestion,
  getCarOptionByVersion,
} from "../../MainBody/api";

import styled from "styled-components";
import ImageUploadingFromLib from "Components/ImageUploading";
import { ResultCarPrice } from '../../MainBody/index'
import { toast } from "react-toastify";

const NoteText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #1f2738;
  margin-bottom: 8px;
  text-align: center;
  font-family: "San Francisco Text";

  &.custom-description {
    margin-bottom: 3px
  }
`;

interface carFormProps {
  onValueChange: (value: string) => void;
}

const CarForm = ({ onValueChange }: carFormProps) => {
  const { user } = useContext(UserContext);
  const theme = useTheme();
  const { t } = useTranslation();
  const [files, setFiles] = useState<any>([]);
  const [selectedCarCompany, setSelectedCarCompany] = useState<SelectOption>({
    id: "",
    label: "",
  });
  const [selectedModel, setSelectedModel] = useState<SelectOption>({
    id: "",
    label: "",
  });
  const [selectedVersion, setSelectedVersion] = useState<SelectOption>({
    id: "",
    label: "",
  });
  const [selectedOption, setSelectedOption] = useState<SelectOption>({
    id: "",
    label: "",
  });
  const [selectedOrigin, setSelectedOrigin] = useState<SelectOption>({
    id: "",
    label: "",
  });

  const vehicleInspectionOcrIdRef = useRef(0)

  const [selectedManufactured, setSelectedManufactured] =
    useState<SelectOption>({ id: "", label: "" });
  const [onRoad, setOnRoad] = useState<string>("");

  const [listCarCompany, setListCarCompany] = useState<SelectOption[]>([]);
  const [listCarInfoModel, setListCarInfoModel] = useState<SelectOption[]>([]);
  const [listOrigin, setListOrigin] = useState<SelectOption[]>([]);
  const [listOption, setListOption] = useState<SelectOption[]>([]);
  const [listVersion, setListVersion] = useState<SelectOption[]>([]);
  const [listManufactured, setListManufactured] = useState<SelectOption[]>([]);

  const [dataFormImage, setDataFromImage] = useState<any>();
  const [isLoadImg, setIsLoadImg] = useState(false);
  const [carPriceValue, setCarPriceValue] = useState<ResultCarPrice>(); // eslint-disable-line
  const [isDoneUploadImageAndFill, setIsDoneUploadImageAndFill] = useState(false)


  const [openCarModel, setOpenCarModel] = useState(false);
  const [openCarManufactured, setOpenCarManufactured] = useState(false);
  const [openCarVersion, setOpenCarVersion] = useState(false);
  const [openCarOption, setOpenCarOption] = useState(false);
  const [openCarOrigin, setOpenCarOrigin] = useState(false);
  const [expandMore, setExpandMore] = useState(false);

  const [, setCorrelationId] = useState<string>("");

  const listHideCarBrand: string[] = useMemo(
    () => [
      "ed70991f-411c-4ac0-8ae4-0c7d66427e59",
      "06df3ccf-b96f-4c95-98fb-801b25295a66",
      "b7b77302-86a5-4d13-a621-d25bb9c48569",
      "f31e885d-dfc5-403c-beb2-da3d0a20c9ce",
      "20d8df48-232c-4711-9eac-b6fbf5d67253",
    ],
    []
  );

  const resetSelect = () => {
    setSelectedCarCompany({ id: "", label: "" });
    setSelectedModel({ id: "", label: "" });
    setSelectedVersion({ id: "", label: "", key: "" });
    setSelectedManufactured({ id: "", label: "" });
    setCorrelationId("");
    setIsDoneUploadImageAndFill(false)
  };

  useEffect(() => {
    (async () => {
      const getCarInfo: CarCompanyInfo[] = await getCarInfoCompany(1000);
      let newListOption: SelectOption[] = [];
      if (getCarInfo && getCarInfo.length > 0) {
        getCarInfo.forEach((company) => {
          const option: SelectOption = {
            id: company.carCompanyId,
            label: company.vehicleBrandName,
          };

          newListOption.push(option);
        });
        listHideCarBrand.forEach((element) => {
          newListOption = newListOption.filter(
            (option) => option.id !== element
          );
        });

        setListCarCompany(newListOption);
      }

      if (dataFormImage) {
        const nameOfCarCompany = dataFormImage.predictedCarCompany;
        const findCarCompany = newListOption.find((company) =>
          company?.label?.includes(nameOfCarCompany)
        );

        if (findCarCompany) {
          const vehicleInspectionOcrId = _.get(dataFormImage, 'vehicleInspectionOcrId')
          setSelectedCarCompany(findCarCompany);
          vehicleInspectionOcrIdRef.current = vehicleInspectionOcrId
        }
      }
    })();
  }, [listHideCarBrand, dataFormImage]);

  useEffect(() => {
    if (selectedCarCompany && selectedCarCompany.id !== "") {
      setSelectedModel({ id: "", label: "" });
      setSelectedVersion({ id: "", label: "" });
      setSelectedManufactured({ id: "", label: "" });
      setSelectedOption({ id: "", label: "" });
      setSelectedOrigin({ id: "", label: "" });
      setListCarInfoModel([]);
      (async () => {
        const listCarInfoModel: CarInfoModel[] = await getCarInfoModel(
          selectedCarCompany?.id || "",
          100
        );
        const newListOption: SelectOption[] = [];

        if (listCarInfoModel && listCarInfoModel.length > 0) {
          listCarInfoModel.forEach((model) => {
            const option: SelectOption = {
              id: model.carModelId,
              label: model.carModelName,
            };
            newListOption.push(option);
          });

          setListCarInfoModel(newListOption);
        }
        if (dataFormImage) {
          const nameOfCarModel = dataFormImage.predictedCarModel;
          const findCarModel = newListOption.find((model) =>
            model?.label?.includes(nameOfCarModel)
          );
          if (findCarModel) {
            setSelectedModel(findCarModel);
          }
        }
      })();
    } else {
      setSelectedModel({ id: "", label: "" });
      setSelectedOption({ id: "", label: "" });
      setSelectedOrigin({ id: "", label: "" });
      setListOption([]);
    }
  }, [selectedCarCompany, dataFormImage]);

  useEffect(() => {
    if (selectedModel && selectedModel.id !== "") {
      setSelectedVersion({ id: "", label: "" });
      setSelectedManufactured({ id: "", label: "" });
      setSelectedOrigin({ id: "", label: "" });
      setListManufactured([]);
      (async () => {
        const listManufacturedYears: number[] = await getCarManufacturedYears(
          selectedCarCompany.id,
          selectedModel.id
        );
        const newListManufactured: SelectOption[] = [];
        if (listManufacturedYears && listManufacturedYears.length > 0) {
          listManufacturedYears.forEach((manufactured) => {
            const option: SelectOption = {
              id: manufactured.toString(),
              label: manufactured.toString(),
            };
            newListManufactured.push(option);
          });
          setListManufactured(newListManufactured);
        }
        if (dataFormImage) {
          const nameOfManufactured = dataFormImage.predictedYear;
          const findManufactured = newListManufactured.find(
            (manufactured) =>
              manufactured?.label === nameOfManufactured.toString()
          );
          if (findManufactured) {
            setSelectedManufactured(findManufactured);
          }
        }
      })();
    } else {
      setSelectedManufactured({ id: "", label: "" });
      setSelectedOrigin({ id: "", label: "" });
    }
  }, [selectedModel, selectedCarCompany, dataFormImage]);

  useEffect(() => {
    if (selectedManufactured && selectedManufactured.id !== "") {
      setSelectedVersion({ id: "", label: "" });
      setSelectedOrigin({ id: "", label: "" });
      setListVersion([]);
      (async () => {
        const listVersion = await getCarVersion(
          selectedCarCompany?.id,
          selectedModel.id,
          selectedManufactured.id
        );
        const newListOption: SelectOption[] = [];
        if (listVersion && listVersion.length > 0) {
          listVersion.forEach((version: any) => {
            const option: SelectOption = {
              id: version.carVersionId,
              label: version.carVersionName,
              key: version.carVersionKey,
              fuel: version.carFuelName,
            };
            newListOption.push(option);
          });
          setListVersion(newListOption);
        }
        if (dataFormImage) {
          const nameOfCarVersion = dataFormImage.predictedCarVersion;
          const findCarVersion = newListOption.find((version) =>
            version?.label?.includes(nameOfCarVersion)
          );
          if (findCarVersion) {
            setSelectedVersion(findCarVersion);
          }
          setIsDoneUploadImageAndFill(true)
        }
      })();
      (async () => {
        const kmTraveld = await getKmTraveledSuggestion(
          selectedManufactured.id
        );
        setOnRoad(kmTraveld.kmTraveledSuggest);
      })();
    } else {
      setSelectedVersion({ id: "", label: "" });
      setSelectedOrigin({ id: "", label: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedManufactured, selectedModel, selectedCarCompany, dataFormImage]);

  useEffect(() => {
    if (selectedVersion && selectedVersion.id !== "") {
      setSelectedOrigin({ id: "", label: "" });
      setListOrigin([]);
      setListOption([]);
      (async () => {
        const listOrigin = await getListOrigin({
          carCompany: selectedCarCompany.label,
          carModel: selectedModel.label,
          carVersion: selectedVersion.label,
          year: parseInt(selectedManufactured.id, 10),
        });
        const newListOption: SelectOption[] = [];
        if (listOrigin && listOrigin.length > 0) {
          listOrigin.forEach((origin: any) => {
            const option: SelectOption = {
              id: origin.originId,
              label: origin.originName,
            };
            newListOption.push(option);
          });
          setListOrigin(newListOption);
          if (listOrigin.length >= 1) {
            setSelectedOrigin({
              id: listOrigin[0].originId,
              label: listOrigin[0].originName,
            });
          }
        }
      })();
      (async () => {
        const listCarOption = await getCarOptionByVersion(
          selectedCarCompany.label,
          selectedModel.label,
          selectedVersion.label,
          parseInt(selectedManufactured.id, 10)
        );
        const newListOption: SelectOption[] = [];
        if (listCarOption && listCarOption.length > 0) {
          listCarOption.forEach((item: any) => {
            const option: SelectOption = {
              id: item.optionId,
              label: item.optionName,
              key: item.optionKey,
            };
            newListOption.push(option);
          });
          setListOption(newListOption);
          if (listCarOption.length >= 1) {
            setSelectedOption({
              id: listCarOption[0].optionId,
              label: listCarOption[0].optionName,
            });
          }
        } else {
          setListOption([]);
        }
      })();
    } else {
      setListOrigin([]);
      setListOption([]);
      setSelectedOrigin({ id: "", label: "" });
      setSelectedOption({ id: "", label: "", key: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVersion]);

  useEffect(() => {
    if (selectedOrigin === null) {
      setSelectedOrigin({ id: "", label: "" });
    }
  }, [selectedOrigin]);

  useEffect(() => {
    if (selectedOption === null) {
      setSelectedOption({ id: "", label: "", key: "" });
    }
  }, [selectedOption]);

  useEffect(() => {
    if (files.length === 0) {
      setCorrelationId("");
      return;
    }
    if (dataFormImage) {
      const traceId = dataFormImage.correlationId;

      if (traceId) {
        setCorrelationId(traceId);
      }
    }
  }, [dataFormImage, files.length]);

  useEffect(() => {
    onValueChange(
      JSON.stringify({
        company: selectedCarCompany,
        model: selectedModel,
        manufactureyear: selectedManufactured,
        version: selectedVersion,
        originId: selectedOrigin,
        kmTraveled: onRoad,
        optionKey: selectedOption,
        vehicleInspectionOcrId: vehicleInspectionOcrIdRef.current
      })
    );
  }, [
    onValueChange,
    selectedCarCompany,
    selectedModel,
    selectedManufactured,
    selectedVersion,
    selectedOption,
    selectedOrigin,
    onRoad
  ]);


  //Check upload image vehicle inspection and fill to auto complete done => show message for each case
  useEffect(() => {
    if (isDoneUploadImageAndFill) {
      const idSelectedModel = _.get(selectedModel, 'id');
      const idSelectedManufactured = _.get(selectedManufactured, 'id');
      const idSelectedVersion = _.get(selectedVersion, 'id');


      if (!idSelectedModel || !idSelectedManufactured || !idSelectedVersion) {
         toast.success(t("upload.notification.server-not-found-codebook"), {
            position: "top-center",
            autoClose: 3000,
          });
      } else {
          toast.success(t("upload.notification.success"), {
            position: "top-center",
            autoClose: 3000,
          });
      }
    }

  }, [isDoneUploadImageAndFill])

  const handleChangeOnRoad = (event: React.ChangeEvent<HTMLInputElement>) => {
    const road = parseFloat(event.target.value);
    if (road < 0) {
      setOnRoad("0");
    } else if (road > 999999) {
      setOnRoad("999999");
    } else {
      setOnRoad(event.target.value);
    }
  };

  const isValidate =
    selectedCarCompany &&
    selectedCarCompany.id !== "" &&
    selectedModel &&
    selectedModel.id !== "" &&
    selectedVersion &&
    selectedVersion.id !== "" &&
    selectedManufactured &&
    selectedManufactured.id !== "";

  return (
    <>
      {user?.ocrEnable ? (
        <Grid item xs={12}>
          <NoteText>{t("form.title.description")}</NoteText>
          <NoteText>{t("form.title.ocrNote")}</NoteText>
          <ImageUploadingFromLib
            setDataFromImage={setDataFromImage}
            setCarPriceValue={setCarPriceValue}
            resetSelect={resetSelect}
            setIsLoadImg={setIsLoadImg}
            isLoadImg={isLoadImg}
            files={files}
            setFiles={setFiles}
          />
        </Grid>
      ) : null}

      <Grid item md={5.5} xs={12}>
        <AutoCompleteInput
          label={t("form.selection.brand.title")}
          listOption={listCarCompany}
          onChange={setSelectedCarCompany}
          value={selectedCarCompany}
          disabled={false}
          placeholder={t("form.selection.brand.placeholder")}
          onClose={() => {
            setOpenCarModel(true);
          }}
          onOpen={() => {
            setOpenCarModel(false);
            setOpenCarVersion(false);
          }}
          files={files}
          setFiles={setFiles}
        />
      </Grid>

      <Grid item md={5.5} xs={12}>
        <AutoCompleteInput
          label={t("form.selection.model.title")}
          listOption={listCarInfoModel}
          onChange={setSelectedModel}
          value={selectedModel}
          disabled={
            selectedCarCompany && selectedCarCompany.id !== "" ? false : true
          }
          placeholder={t("form.selection.model.placeholder")}
          open={
            selectedCarCompany && selectedCarCompany.id !== "" && openCarModel
          }
          onClose={() => {
            setOpenCarModel(false);
            setOpenCarManufactured(true);
          }}
          onOpen={() => {
            setOpenCarModel(!openCarModel);
            setOpenCarManufactured(false);
            setOpenCarVersion(false);
          }}
        />
      </Grid>

      <Grid item md={5.5} xs={12}>
        <AutoCompleteInput
          label={t("form.selection.manufacture-year.title")}
          listOption={listManufactured}
          onChange={setSelectedManufactured}
          value={selectedManufactured}
          disabled={selectedModel && selectedModel.id !== "" ? false : true}
          placeholder={t("form.selection.manufacture-year.placeholder")}
          open={selectedModel && selectedModel.id !== "" && openCarManufactured}
          onClose={() => {
            setOpenCarManufactured(false);
            setOpenCarVersion(true);
          }}
          onOpen={() => {
            setOpenCarManufactured(!openCarManufactured);
            setOpenCarVersion(false);
          }}
        />
      </Grid>

      <Grid item md={5.5} xs={12}>
        <AutoCompleteInput
          label={t("form.selection.version.title")}
          listOption={listVersion}
          onChange={setSelectedVersion}
          value={selectedVersion}
          disabled={
            selectedManufactured && selectedManufactured.id !== ""
              ? false
              : true
          }
          placeholder={t("form.selection.version.placeholder")}
          open={
            selectedManufactured &&
            selectedManufactured.id !== "" &&
            openCarVersion
          }
          onClose={() => {
            setOpenCarVersion(false);
          }}
          onOpen={() => {
            setOpenCarVersion(!openCarVersion);
          }}
        />
      </Grid>

      {listOption.length > 0 && (
        <Grid item xs={12} md={5.5}>
          <AutoCompleteInput
            label="Option"
            listOption={listOption}
            onChange={setSelectedOption}
            value={selectedOption}
            placeholder="Option"
            open={openCarOption}
            onClose={() => {
              setOpenCarOption(false);
            }}
            onOpen={() => {
              setOpenCarOption(true);
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} md={12}>
        <Button
          sx={{
            textTransform: "unset",
            border: "none",
            color: "#4E65FF",
            ":hover": {
              border: "none",
              backgroundColor: "transparent",
            },
          }}
          onClick={() => setExpandMore(!expandMore)}
          variant="outlined"
          endIcon={
            !expandMore ? <IconExpandInformation /> : <IconHideInfomation />
          }
        >
          Thông tin bổ sung{" "}
        </Button>
      </Grid>
      {expandMore && (
        <>
          {listOrigin.length > 0 && (
            <Grid item xs={12} md={5.5}>
              <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                <AutoCompleteInput
                  label="Xuất xứ"
                  listOption={listOrigin}
                  onChange={setSelectedOrigin}
                  value={selectedOrigin}
                  placeholder="Xuất xứ"
                  open={openCarOrigin}
                  onClose={() => {
                    setOpenCarOrigin(false);
                  }}
                  onOpen={() => {
                    setOpenCarOrigin(true);
                  }}
                />
                <FormHelperText> </FormHelperText>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} md={5.5}>
            <FormControl variant="standard" sx={{ minWidth: "100%" }}>
              <Typography
                sx={{
                  fontFamily: "San Francisco Text",
                  fontWeight: 400,
                  fontSize: "14px",
                  padding: "5px 0px",
                  color: theme.palette.mode === "dark" ? "white" : "black",
                }}
              >
                Số km đã đi
              </Typography>
              <TextField
                value={onRoad}
                placeholder="Số km đã đi"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Km</InputAdornment>
                  ),
                }}
                size="small"
                onChange={handleChangeOnRoad}
                type="number"
              />
              {selectedManufactured && selectedManufactured.id !== "" && (
                <FormHelperText>
                  Đây là gợi ý số km đã đi trung bình theo tuổi xe của bạn
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </>
      )}
      <Grid item xs={12} md={12}>
        <Button
          style={{
            width: "44%",
            height: "50px",
            background: `${isValidate ? "#5768FF" : "#f0f0f0"}`,
            borderRadius: "12px",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "20px",
            textAlign: "center",
            letterSpacing: "0.005em",
            color: `${isValidate ? "#ffffff" : "#bdbdbd"}`,
            textTransform: "unset",
            wordBreak: "keep-all",
            display: "block",
            margin: "0 auto",
          }}
          disabled={!isValidate}
          type="submit"
        >
          Tra cứu
        </Button>
      </Grid>
    </>
  );
};

export default CarForm;
